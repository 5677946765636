





















































































































import { Component, Model, Vue } from "vue-property-decorator";
import { getStatusColor } from "@/services/helpers-status";
import { IconsType, Item } from "@/types";
import { mdiClose } from "@mdi/js";
import { namespace } from "vuex-class";
const ApplicationModule = namespace("ApplicationModule");
const AppModule = namespace("AppModule");
@Component({
  components: {
    Stepper: () => import("@/components/Stepper/Stepper.vue"),
    LabelStatus: () => import("@/components/Label/LabelStatus.vue")
  }
})
export default class CardTimelineNew extends Vue {
  @Model("operation", { type: String }) readonly selectedOperation!: string;
  //
  // COMPUTED
  get model() {
    return this.selectedOperation;
  }
  set model(payload) {
    this.$emit("operation", payload);
  }
  @ApplicationModule.State
  operations!: string[];
  @ApplicationModule.State
  number!: string;
  @ApplicationModule.State
  item!: Item;
  @AppModule.Getter
  smallScreen!: boolean;
  //
  // DATA
  step = 1;
  comment = "";
  sending = false;
  applicationEditDialog = false;
  commentRequired: string[] = ["rejected", "returned", "approved"];
  availableOperations: string[] = [
    "approved",
    "rejected",
    "cancelled",
    "returned",
    "paid"
  ];
  icons: IconsType = {
    close: mdiClose
  };
  //
  // METHODS
  color(status: string): string {
    return getStatusColor(status);
  }
  onSelect(operation: string) {
    this.model = operation;
    this.step = 2;
  }
  onBack() {
    this.model = "";
    this.comment = "";
    this.step = 1;
  }
  async onUpdated() {
    await this.$store.dispatch(
      "ApplicationModule/fetchApplication",
      this.number
    );
    this.applicationEditDialog = false;
  }
  onSend() {
    this.sending = true;
    setTimeout(async () => {
      const res = await this.$store.dispatch("ApplicationModule/changeStatus", {
        status: this.selectedOperation,
        comment: this.comment
      });
      if (res) {
        this.step = 1;
        this.comment = "";
        this.model = "";
      } else await this.$store.dispatch("SnackbarModule/set");
      this.sending = false;
    });
  }
  onCloseEditApplicationDialog() {
    this.applicationEditDialog = false;
    this.$store.commit("ItemModule/CLEAR_DOCUMENTS");
    this.$store.commit("ItemModule/CLEAR_FIELDS");
    this.$store.commit("ItemModule/SIGNED_TEMPLATE", null);
    this.$store.commit("ItemModule/SIGNED_CONSENT", null);
  }
  //
  // LIFECYCLE HOOKS
  destroyed() {
    this.model = "";
  }
}
